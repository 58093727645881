import { reactive } from 'vue'

export const spinContainer = reactive({})

export function createSpinPot (key) {
  spinContainer[key] = reactive({
    spinning: false,
    tip: ''
  })
  return spinContainer[key]
}